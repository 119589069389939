import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/react';
import { breakPoints, border, dropShadow } from '../constants/constant';
import { colors } from '../constants/colors';
import { Image } from './Image';

const eyecatchDefault = 'eyecatch-default.png';

export const SectionEyecatch = ({
    pageTitle,
    pageSentences,
    imageName,
    className,
    children,
}) => (
    <section className={`project ${className || ``}`} css={sectionStyle}>
        <div css={imageStyle}>
            <Image imageName={imageName} alt="eyecatch" />
        </div>
        {children || (
            <div css={childrenStyle}>
                <h1 css={h1Style}>{pageTitle}</h1>
                <span css={borderStyle} />
                <h3 css={h3Style}>{pageSentences}</h3>
            </div>
        )}
    </section>
);

SectionEyecatch.propTypes = {
    pageTitle: PropTypes.string,
    pageSentences: PropTypes.string,
    imageName: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

SectionEyecatch.defaultProps = {
    pageTitle: ``,
    pageSentences: ``,
    imageName: eyecatchDefault,
};

const sectionStyle = css`
    position: relative;
    color: ${colors.white};
    overflow: hidden;
    width: 100vw;
    height: 281px;
    margin: 0 calc(50% - 50vw);
    z-index: -1;
    background: #000000 0% 0% no-repeat padding-box;
    text-align: center;
    @media screen and (max-width: ${breakPoints.mobile}) {
        height: 200px;
    }
`;

const imageStyle = css`
    width: 100%;
    height: 100%;
    div {
        width: 100%;
        height: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        filter: brightness(65%) contrast(105%) blur(4px);
        object-position: 50% 50%;
        object-fit: cover;
    }
`;

const childrenStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    width: 100%;
    height: 100%;
`;

const h1Style = css`
    font-size: 5.6rem;
    font-family: 'vdl-logojr', 'M PLUS 1p', sans-serif;
    font-weight: 500;
    line-height: 1;
    color: ${colors.white};
    text-shadow: ${dropShadow};
    margin-bottom: 0.8rem;
    @media screen and (max-width: ${breakPoints.mobile}) {
        font-size: 32px;
    }
`;

const borderStyle = css`
    border-width: ${border} 8rem;
    border-style: solid;
    border-top: none;
    border-color: ${colors.primary};
    margin-bottom: 16px;
    @media screen and (max-width: ${breakPoints.mobile}) {
        border-width: ${border} 4rem;
    }
`;

const h3Style = css`
    font-weight: normal;
    text-shadow: ${dropShadow};
    @media screen and (max-width: ${breakPoints.mobile}) {
        font-size: 16px;
    }
`;
